.console {
  display: none;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: #111;
  padding-bottom: 1rem;
}
.console [data-method] {
  padding-left: 1rem;
}
[data-method] > div:first-child {
  position: relative;
  top: 6px;
}
[role="treeitem"] > div:first-child span:first-child {
  margin-top: 2px;
  transition: transform .2s ease;
}
.console-header {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: .5rem 1rem;
  color: #fff;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #111;
  border-bottom: 1px solid rgba(255,255,255,0.03);
}
.console-toggle {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: .25rem .5rem;
  min-width: 4rem;
  border: 0;
  background-color: #111;
  color: #fff;
  font-family: var(--base-font);
  font-size: .75rem;
  border-radius: 3px;
  transition: all .2s ease;
}
.console-toggle:hover {
  background-color: #222;
}

@media (min-width: 866px) {
  .app {
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 100vh;
  }
  .app-console {
    grid-template-columns: 1fr 2fr minmax(400px, 1fr);
  }
  .app-console .console {
    display: block;
  }
  .console-toggle {
    display: block;
  }
  .checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
  }
  .checkout-console {
    grid-template-columns: 1fr 2fr minmax(400px, 1fr);
  }
  .checkout-console .console {
    display: block;
  }
  .payment-main {
    align-self: flex-start;
    padding-top: 5rem;
  }
}