.site-header {
  padding-top: 3rem;
}
.logo {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-family: Audiowide;
  text-transform: lowercase;
  font-size: 1.75rem;
  letter-spacing: -0.01em;
  color: #222;
}
.logo img {
  display: block;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}
.description {
  display: none;
  margin-top: 3rem;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  color: #666;
}
.cta {
  display: none;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  margin-top: auto;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: .5rem;
  padding: 1rem;
  line-height: 1.2;
  color: #333;
  font-size: .875rem;
}
.cta svg {
  fill: #FF5F6D;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}


@media (min-width: 866px) {
  .site-header {
    background-color: #fff;
    background-image: url(../../img/noise.png);
    background-size: 88px auto;
    padding: 3rem 0 5rem;
    box-shadow: inset 1px 0 12px rgba(0,0,0,0.05);
  }
  .site-header .container {
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 3rem;
    min-height: 100%;
  }
  .logo img {
    width: 5.5rem;
    height: 5.5rem;
  }
  .description {
    display: block;
  }
  .cta {
    display: flex;
  }
}