*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  --base-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  /* background-image: url(../img/noise.png); */
  background-repeat: repeat;
  background-size: 88px 88px;
  color: #222;
  line-height: 1.5;
}
a {
  color: #FF5F6D;
}
:focus-visible {
  outline-color: #FF5F6D;
}
[hidden] {
  display: none;
}
.container {
  margin: 0 auto;
  max-width: 40rem;
  padding-left: 2rem;
  padding-right: 2rem;
}


@media (min-width: 866px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.card {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin-bottom: 5rem;
}
.btn {
  appearance: none;
  display: block;
  width: 100%;
  background: #FF5F6D;
  color: #fff;
  border-radius: .5rem;
  padding: .9rem 1rem;
  line-height: 1;
  border: 0;
  font-family: var(--base-font);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease;
  position: relative;
  text-decoration: none;
}
.btn:hover {
  background: #FF4B56;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 4px rgba(255, 75, 86, 0.24);
}
.btn:disabled {
  cursor: default;
}
.btn + .btn {
  margin-top: .5rem;
}
.btn.alt {
  background: transparent;
  color: #FF4B56;
}
.btn.alt:hover {
  background: transparent;
  color: #111;
}
.btn .loading {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%
}
.btn:disabled .loading {
  opacity: 1;
}
.btn:disabled .text {
  opacity: 0;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner-container {
  color: #828282;
}
.spinner {
  display: block;
  width: 1rem;
  height: 1rem;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner 0.75s linear infinite;
}
.spinner-container .spinner {
  margin-left: auto;
  margin-right: auto;
}
main {
  align-self: center;
}
form {
  padding: 3rem 0 5rem;
}
h1 {
  font-size: 1.75rem;
  line-height: 1.1;
  letter-spacing: -0.01em;
  position: relative;
}
h1::after {
  content: '';
  display: block;
  height: 1px;
  border-top: 3px solid #FFC371;
  width: 2rem;
  margin-top: .5rem;
}
h2 {
  font-size: 1.3125rem;
  font-weight: 700;
}
fieldset {
  display: grid;
  margin: 0;
  padding: 0;
  border: 0;
  gap: 1rem;
  margin-bottom: 2rem;
}
label {
  display: block;
  font-size: .875rem;
  color: #777;
}
.split-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.split-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
@media (min-width: 568px) {
  .split-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
input:not([type="radio"]),
select {
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: .6rem .5rem;
  border-radius: .25rem;
  line-height: 1;
  font-family: var(--base-font);
  font-size: 1rem;
  margin-top: .125rem;
  color: #222;
}
input:focus,
select:focus {
  background-color: #fff;
  outline-color: #FF5F6D;
}
select {
  -webkit-appearance: none;
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, #999 50%),
    linear-gradient(135deg, #999 50%, transparent 50%);
  background-position:
    calc(100% - 16px) 1rem,
    calc(100% - 11px) 1rem;
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
}
select:invalid {
  color: #777;
}
input[type="radio"],
input[type="checkbox"] {
  accent-color: #ff4353;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}
.radio-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.small {
  font-size: .75rem;
  color: #777;
}
.fi {
  font-size: .75rem;
  margin-top: .25rem;
}
.banner {
  padding: .5rem 1rem;
  font-size: .75rem;
  background-color: rgba(0,0,0,0.85);
  color: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.banner svg {
  display: none;
  width: 55px;
  height: 16px;
  margin-right: .5rem;
  fill: currentColor;
  vertical-align: middle;
  top: -1px;
  position: relative;
}
.banner a {
  color: #6FCF97;
  font-weight: 500;
  text-decoration: none;
}

@media (min-width: 568px) {
  .banner {
    border-radius: .5rem;
    font-size: .875rem;
    bottom: 1rem;
    left: 50%;
    right: auto;
    width: fit-content;
    transform: translateX(-50%);
  }
  .banner svg {
    display: inline-block;
  }
}